<template>
  <base-section
    id="affiliates-two"
    space="36"
  >
    <v-container>
      <base-section-heading title="REGISTERED UNDER" />
      <v-row>
        <v-col
          v-for="number in 3"
          :key="number"
          cols="4"
        >
          <base-img
            :src="require(`@/assets/affiliateTwo-${number}.png`)"
            color="grey"
            contain
            height="90"
            width="120"
            class="mx-auto"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAffiliatesTwo',
  }
</script>
